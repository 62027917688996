export const environment = {
  production: true,
  APPUrl: "https://kml.dev.kpmg.com/",
  APIUrl: "https://kmlapi.dev.kpmg.com/api/",
  ClientID: "4a0fc81a-68cf-410a-aa05-ac45714076db",
  instrumentationKey: "77684428-ceea-4f4c-b293-fdfa64d10655",
  AuthorityURL: "https://login.microsoftonline.com/cf59843c-25a6-4e9e-a632-73d41e867a1f",
  authenticationMessage: "Auth Token expired",
  sessionTimoutMessage: "Session Timed out - Please login",
  idle_timer: 1200,
  refreshtoken_timer: 2000000,
  gridLoadValue: 100,
  handsontableLicenseKey: "3bb31-c3a50-e1115-84e35-43e2a"
};
